<!--
 * @Author: 刘格优
 * @Date: 2021-01-19 16:50:21
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-01-19 17:07:35
-->
<!--  -->
<template>
  <div class="allbox">
      该链接为错误链接，请联系身边业务员
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},
  methods: {}
}

</script>
<style scoped>
.allbox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
</style>